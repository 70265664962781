<template>
  <div>
    <h4 class="subtitle-form">Date et heures</h4>
    <div class="row-resize">
      <b-row>
        <!-- Start Date Text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg"><img :src="iconCalendar" alt="calendar" />
              <span class="text-activity-notes subtitle-params"> Date démarrage </span></b-col>
        <!-- Start hour text-->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg"><img :src="iconClock" alt="clock" />
              <span class="text-activity-notes subtitle-params"> Heure de début </span></b-col>
      </b-row>

      <b-row>
        <!-- Start Date Input-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <input type="date" id="example-datepicker" ref="startDate" v-model="startDate" @change="onChangeStartDate()" placeholder="yyyy-mm-dd" required/>
          </div>
        </b-col>
        <!-- Start hour input -->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <div class="inputs-params">
            <b-input type="time" v-model="startTime" ref="startTime" size="sm" @change="onChangeTime()" placeholder="hh:mm"/>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <!-- End Date Text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg"><img :src="iconCalendar" alt="calendar" />
              <span class="text-activity-notes subtitle-params"> Date de fin</span></b-col>
        <!-- End hour text-->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg">
          <img :src="iconClock" alt="clock" />
          <span class="text-activity-notes subtitle-params"> Heure de fin
          </span>
        </b-col>
      </b-row>

      <b-row>
        <!-- End Date Input-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <input type="date" id="example-datepicker" ref="endDate" v-model="endDate" @change="onChangeEndDate()" placeholder="yyyy-mm-dd"/>
          </div>
        </b-col>        
        <!-- End hour input-->
        <b-col cols="6" class="text-infos shape-second-cell whitout-bg"><div class="inputs-params">
            <b-input type="time" v-model="endTime" ref="endTime" size="sm" @change="onChangeTime()" placeholder="hh:mm"/>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <!-- Elapsed Time text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <img :src="iconTimelapse" alt="timelapse" />
          <span class="text-activity-notes subtitle-params"> Temps passé
          </span>
        </b-col>
        <!-- Working time text -->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg"><img :src="iconTimelapse" alt="timelapse" />
        <span class="text-activity-notes subtitle-params"> Temps d'activité matériel </span></b-col>
      </b-row>

      <b-row>
        <!-- Elapsed time input -->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <b-input type="text" ref="elapsedTime" v-model="elapsedTime" size="sm" @change="onChangeElapsedTime()"/>
          </div>
        </b-col>
        <!-- Working time input -->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <b-input type="text" ref="workingTime" v-model="workingTime" size="sm" @change="onChangeWorkingTime()"/>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import clock from "@/assets/images/clock.svg";
import calendar from "@/assets/images/calendar.svg";
import timelapse from "@/assets/images/timelapse.svg";
import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import {checkDatePattern, checkTimePattern, formatDate} from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      iconClock: clock,
      iconCalendar: calendar,
      iconTimelapse: timelapse,
      startDate: "",
      endDate: "",
      elapsedTime: "",
      workingTime: "",
      startTime: "08:00",
      endTime: "",
      errStartDate: false,
      loadComponent: false,
    };
  },
  created() {
    this.init();
  },
  components: {},
  methods: {
    init: function() {
      this.loadComponent = false;
      if (Object.keys(this.currentEntry) == 0) {
        this.loadComponent = true;
        return;
      }
      // Si on modifie une activité on initialise les dates et heures
      // à partir de l'activité actuel
      if (this.$route.name === "activity.edit") {
        this.startDate = this.currentEntry.startDate.slice(0, 10);
        this.startTime = formatDate(this.currentEntry.startDate, "time");

        if (this.currentEntry.endDate != null) {
          this.endDate = this.currentEntry.endDate.slice(0, 10);
          this.endTime = formatDate(this.currentEntry.endDate, "time");
        }

        if (this.currentEntry.elapsedTime != null)
          this.elapsedTime = this.convertSecsToTime(this.currentEntry.elapsedTime, "hm");

        if (this.currentEntry.workingTime != null)
          this.workingTime = this.convertSecsToTime(this.currentEntry.workingTime, "hm");
      }
      this.loadComponent = true;
    },
    /**
     * Description: Synchronise la date de fin à la date de début.
     */
    onChangeStartDate() {
      this.endDate = this.startDate;
      this.onChangeTime();
    },

    /**
     * Description: on change end date 
     */
     onChangeEndDate(){
      // Check if End date is within limits
      var maxEndDate = this.initDate(this.startDate, this.startTime);
      maxEndDate.setDate(maxEndDate.getDate()+7);

      var givenEndDate = this.initDate(this.endDate, this.endTime);

      if(givenEndDate > maxEndDate ){
          this.$refs.endDate.setCustomValidity(this.$i18n.t("Date excédant 7j"));
          this.endDate = formatDate(maxEndDate, "date", "-");
        }

      this.onChangeTime();
     },

    /**
     * Description: Initialise une date.
     * @param {string}      date Une date jj/mm/aaaa ou aaaa-mm-jj.
     * @param {string}      time Une heure HH:mm.
     * @return {Date}       Retourne une date.
     */
    initDate(date, time = "") {
      let TimeArray = time.split(':')
      let hours = TimeArray[0] != undefined && TimeArray[0] != "" ? TimeArray[0] : 0;
      let minutes = TimeArray[1] != undefined && TimeArray[1] != "" ? TimeArray[1] : 0;

      let newDate = new Date(date);
      newDate.setHours(hours, minutes);
      return newDate;
    },

    /**
     * Description: Convertie et retourne le elapsedTime en secondes.
     */
    getTime() {
      // If ElapsedTime is more than 99 hours, the string length is 6
      if(this.elapsedTime.length > 5){
        var time = this.convertTimeToSecs(this.elapsedTime.slice(0, 3), this.elapsedTime.slice(4, 6));
      } else {
        var time = this.convertTimeToSecs(this.elapsedTime.slice(0, 2), this.elapsedTime.slice(3, 5));
      }
      
      
      if (time != "NaN" && time != 0) return time;
      return null;
    },

    /**
     * Description: Retourne la date de début.
     */
    getStartDate() {
      this.errStartDate = false;

      if (this.startTime === "") this.startTime = "08:00";
      let TimeArray = this.startTime.split(':')
      let hours = TimeArray[0] != undefined ? TimeArray[0] : 0;
      let minutes = TimeArray[1] != undefined ? TimeArray[1] : 0;

      var date = new Date(this.startDate);
      date.setHours(hours, minutes);

      if (date != "Invalid Date") return date;

      this.errStartDate = true;
      return null;
    },

    /**
     * Description: Retourne la date de fin.
     */
    getEndDate() {
      var date = new Date(this.endDate + " " + this.endTime);

      if (this.endTime === "" || this.endTime === 0) return null;

      if (date != "Invalid Date") return date;
      return null;
    },

    /**
     * Description: Synchronise le workingTime avec elapsedTime et met à jour la date de fin.
     */
    onChangeElapsedTime() {
      if(this.checkElapsedTimePattern(this.elapsedTime)){

        if (this.startDate == "") {
          this.workingTime = this.elapsedTime;
          return;
        }

        var secs = this.convertTimeToSecs(this.elapsedTime.slice(0, 2), this.elapsedTime.slice(3, 5));

        let TimeArray = this.startTime.split(':')
        let hours = TimeArray[0] != undefined ? TimeArray[0] : 0;
        let minutes = TimeArray[1] != undefined ? TimeArray[1] : 0;

        var newEndDate = new Date(this.startDate);
        newEndDate.setHours(hours, minutes, secs);
        this.endDate = formatDate(newEndDate, "date", "-");
        this.endTime = formatDate(newEndDate, "time");
      }

      if(this.elapsedTime<this.workingTime || this.workingTime=='')
      {
        this.workingTime = this.elapsedTime;
      }
    },

    /**
     * Description: Synchronise le elapsedTime avec le workingTime et met à jour la date de fin.
     */
    onChangeWorkingTime() {
      if(this.checkElapsedTimePattern(this.workingTime)){
        if (this.startDate == "") {
          this.elapsedTime = this.workingTime;
          return;
        }

        var secs = this.convertTimeToSecs(this.workingTime.slice(0, 2), this.workingTime.slice(3, 5));

        let TimeArray = this.startTime.split(':')
        let hours = TimeArray[0] != undefined ? TimeArray[0] : 0;
        let minutes = TimeArray[1] != undefined ? TimeArray[1] : 0;

        var newEndDate = new Date(this.startDate);
        newEndDate.setHours(hours, minutes, secs);

        // Format date and time using formatDate function
        this.endDate = formatDate(newEndDate, "date", "-");
        if(this.workingTime > this.elapsedTime){
          this.endTime = formatDate(newEndDate, "time");
          this.elapsedTime = this.workingTime;
        }
      }
    },

    /**
     * Description: Synchronise le elapsedTime et le workingTime en fonction de l'heure de début
     * et de fin renseignée.
     */
    onChangeTime() {
      if (this.startTime == "" || this.endTime == "" || this.startDate == "") return;

      var date1 = this.initDate(this.startDate, this.startTime);
      var date2 = this.initDate(this.endDate, this.endTime);

      // Si la date de début est supérieur à la date de fin
      // alors on incrémente la date de fin de 1 jour
      // Sinon on s'assure de garder la date de fin égale à la date de début
      if (date1.getTime() > date2.getTime()) {
        date2 = this.initDate(this.startDate, this.endTime);
        date2.setDate(date2.getDate() + 1);
        this.endDate = date2.toJSON().slice(0, 10);
      } else {
        date2 = this.initDate(this.endDate, this.endTime);
        this.endDate = date2.toJSON().slice(0, 10);
      }

      var secs = parseInt(Math.floor((date2.getTime() - date1.getTime()) / 1000));
      var tmpElapsedTime = this.convertSecsToTime(secs, "hm");

      if (tmpElapsedTime != "NaN:NaN") {
        this.elapsedTime = tmpElapsedTime;
        this.workingTime = tmpElapsedTime;
      }
    },

    /**
     * Description: Vérifie si l'heure renseigné est valide.
     * @param {string}   time Heure renseigné (HH:mm) ou un nombre d'heure (7).
     * @return {string}  Retourne l'heure soit en HH:mm ou un nombre d'heure (7)
     * si l'heure est valide sinon retourne false.
     */
    checkTime(time) {
      if (time.indexOf(":") == -1 && !isNaN(time)) {
        return time;
      } else if (time.indexOf(":") != -1) {
        let date = new Date("2020-01-01 " + time);
        if (date != "Invalid Date") return time;
        else return false;
      } else {
        return false;
      }
    },

    // To check if "time" is below 48h
    checkElapsedTimePattern(time) {
      var time_regex = /^([0-2][0-4][0-9]|[0-9][0-9]):[0-5][0-9]$/
      if (!(time_regex.test(time)) && time != "" && time != null) {
        return false;
      }else{
        return true;
      }
    },

    /**
     * Description: Formate les heures, minutes et secondes en rajoutant un 0 si nécessaire.
     * @param {string}   hours Nombres d'heures.
     * @param {string}   minutes Nombre de minutes.
     * @param {string}   secondes Nombres de secondes.
     * @return {object}  Return un objet contenant les attribut heures, minutes, secondes soit
     * objet["hours"], objet["minutes"], objet["secondes"].
     */
    formatHours: function(hours = "", minutes = "", secondes = "") {
      var clock = {};

      clock["hours"] = hours;
      clock["minutes"] = minutes;
      clock["secondes"] = secondes;

      if (hours.length == 1) clock["hours"] = "0" + hours;
      if (minutes.length == 1) clock["minutes"] = "0" + minutes;
      if (secondes.length == 1) clock["secondes"] = "0" + secondes;

      return clock;
    },
    /**
     * Description: Convertie des secondes en "heures:minutes:secondes" et renvoie une string
     * "heures:minutes:secondes".
     * @param {number}   secs Nombres de secondes.
     * @param {string}   opt Argument optionnel qui peut être 'h' pour heures, 'm' pour minutes
     * ou 'hm' pour heures et minutes.
     * @return {string}  Par défaut une string "heures:minutes:secondes" sinon heures, minutes ou
     * "heures:minutes" selon l'option passé au paramètre opt.
     */
    convertSecsToTime: function(secs, opt = "", delimiter = ":") {
      var hours = parseInt(secs / 3600);
      var minutes = parseInt((secs - hours * 3600) / 60);
      var secondes = secs - (3600 * hours + 60 * minutes);

      if (hours < 0) hours = 0;
      if (minutes < 0) minutes = 0;
      if (secondes < 0) secondes = 0;

      var clock = this.formatHours(hours.toString(), minutes.toString(), secondes.toString());

      if (opt == "h") return clock["hours"];
      else if (opt == "m") return clock["minutes"];
      else if (opt == "hm") return clock["hours"] + delimiter + clock["minutes"];
      return clock["hours"] + ":" + clock["minutes"] + ":" + clock["secondes"];
    },

    /**
     * Description: Convertie en secondes, les heures et minutes passé en paramètres.
     * @param {string}   hours Nombres d'heures.
     * @param {string}   minutes Nombres de miniutes.
     * @return {Number}  Nombre de secondes.
     */
    convertTimeToSecs(hours = "", minutes = "") {
      var hoursToSecs = 0;
      var minutesToSecs = 0;

      if (hours != "") hoursToSecs = parseInt(hours * 3600);
      if (minutes != "") minutesToSecs = parseInt(minutes * 60);

      return hoursToSecs + minutesToSecs;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      // if(!checkDatePattern(this.startDate) || !checkTimePattern(this.startTime) || !checkTimePattern(this.endTime))
      //   return false;
      this.isValid = true;
      return this.loadComponent;
    },
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  computed: {
    ...mapGetters({ currentActivity: "activity/currentEntry" }),
  },
  watch: {
    currentEntry: {
      handler: function() {
        this.init();
      },
      deep: true,
    },
    startDate: {
      handler: async function() {
        if(!checkDatePattern(this.startDate)) {
          this.$refs.startDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        }else{
          this.$refs.startDate.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {startDate: this.getStartDate(),});
        }
      }
    },
    startTime: {
      handler: async function() {
        if(!checkTimePattern(this.startTime)) {
          this.$refs.startTime.setCustomValidity(this.$i18n.t("commons.details.timePatternError"))
        }
        else {
          this.$refs.startTime.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {startDate: this.getStartDate(),});
        }
      }
    },
    elapsedTime: {
      handler: async function() {
        if(!this.checkElapsedTimePattern(this.elapsedTime)){
          this.$refs.elapsedTime.setCustomValidity(this.$i18n.t("Dépasse le temps maximal"));
        }
        else {
          this.$refs.elapsedTime.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {elapsedTime: this.getTime(),});
        }
      }
    },
    workingTime: {
      handler: async function() {
        if(!this.checkElapsedTimePattern(this.workingTime)){
          this.$refs.workingTime.setCustomValidity(this.$i18n.t("Dépasse le temps maximal"))
        } else {
          this.$refs.workingTime.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {workingTime: this.getTime(),});
        }
      }
    },
    endTime: {
      handler: async function() {
        if(!checkTimePattern(this.endTime)) {
          this.$refs.endTime.setCustomValidity(this.$i18n.t("commons.details.timePatternError"))
        }
        else {
          this.$refs.endTime.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {endDate: this.getEndDate(),});
        }
      }
    },
    endDate: {
      handler: async function() {
        if(!checkDatePattern(this.endDate)) {
          this.$refs.endDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        } else {
          this.$refs.endDate.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, {endDate: this.getEndDate(),});
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 3px;
  margin-bottom: 4px;
}

/**
 * Change the subtitle color.
 */
.change-subtitle-color {
  color: $primary-color;
}

/**
 * Inputs params.
 */
.inputs-params {
  margin: 2px 10px 3px 0px;
  // width: 47%;
}

/**
 * Subtitle params.
 */
.subtitle-params {
  margin: auto 0px 2px 0px;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

.onSameLine {
  text-align: center;
  display: flex;
  justify-content: center;
}

/**
 * Parameter error startDate.
 */
.error-start-date {
  // text-align: center;
  color: $danger-color;
}

/**
 * Resize rows.
 */
.row-resize{
    padding-left: 16px;
}
</style>
